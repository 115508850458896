/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

import heroBg from '../../../../content/assets/realize/lp/implante/images/dentista-ceilandia-dra-denise-fontenelle.jpg'

export default {
  heroContainer: {
    bg: `#414141`,
    pt: [6, 6],
    '::before': {
      content: `" "`,
      size: `full`,
      position: `absolute`,
      right: 0,
      top: `5%`,
      //zIndex: -2,
      //background: `url(${heroBg}) no-repeat center center`,
      opacity: 0.3,
      backgroundSize: `100%`
    }
  }
}
